import { useAuth } from "../contexts/AuthContext";
import { useState } from 'react';
import OpenAI from 'openai';
import styles from "./Dashboard.module.css";
import { Link } from "react-router-dom";
import { Badge, Button, Card, Input, Loader, Progress, Skeleton, Container, Center, Modal, Text, Anchor, Group, Stack, Checkbox, ThemeIcon, Radio, Tooltip} from "@mantine/core";
import Sidebar from "./Sidebar";
import { IconCircleCheck, IconEye, IconRectangle, IconRectangleVertical, IconSparkles, IconSquare, IconVideoPlus } from "@tabler/icons-react";
import UpgradeCard from "./UpgradeCard";
import { useDisclosure } from "@mantine/hooks";
import MaintenanceOverlay from "./MaintenanceOverlay";
// import AWS from 'aws-sdk';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true // Note: In a production app, you should use a backend to make API calls
});

const s3 = new AWS.S3({
  accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
  secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
  region: import.meta.env.VITE_AWS_REGION,
});

const getContentType = (filename) => {
  const ext = filename.split('.').pop().toLowerCase();
  switch (ext) {
    case 'mp3':
      return 'audio/mpeg';
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'webp':
      return 'image/webp';
    default:
      return 'application/octet-stream';
  }
};

const base64ToBlob = (base64, type = 'application/octet-stream') => {
  const binary = atob(base64.replace(/\s/g, ''));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  return new Blob([buffer], { type: type });
};

const generateFolderName = (title, uid) => {
  const sanitizedTitle = title.replace(/[^a-zA-Z0-9]/g, '-');
  const datetime = new Date().toISOString().replace(/[:.]/g, '-');
  return `${sanitizedTitle}-${datetime}-${uid}`;
};


const PROGRESS = {
  0: 100,
  1: 50,
  2: 34,
  3: 25,
  4: 20,
  5: 16,
  6: 0
}

const PROMPT_STYLE = [
  "high contrast, epic cinematic, soft studio lights, rim light, absurd, amazing, funny, itricate, hyper detailed, ultra realistic, soft colors, 8K",
  "aesthetic, painterly style, modern ink, expressive pose, urbanpunk, abstract texture multilayer background",
  "abstract style. non-representational, colors and shapes, expression of feelings, imaginative, highly detailed,",
  "gloomy background, brutal, majestic, cinematic, dramatic, digital painting, expressionism, emotionally profound, close up, unforgettable, high quality",
  "Surreal, ethereal, dreamy, mysterious, fantasy, highly detailed. psychedelic, vibrant. digital artwork, illustrative, painterly, matte painting. key visual. octane render, , volumetric, dramatic lighting, glossy, lense flare, shiny surface, black and fire orange color scheme. bokeh, epic, grainy",
  "eye-catching, beautiful details, overaction, fever, complex background, chaos composition, epic. 32k, intricate background, hyperrealistic, 32k oil painting, oil splash, liquid splash",
  "digital painting meets analog film, noir style, hyper-realistic, dark black, night, heavy fog, wet, textured, ambient light, rim light, highly detailed",
  "rainy, digital painting , dramatic, gloomy, despair, looking to bottom, dark tones, high quality, unforgettable, very intricate, masterpiece",
  "cyberpunk, black, hyperdetailed, hyperrealism extremely detailed epic masterwork, dark fantasy detailed matte 8k resolution concept art intricately detailed Splash art trending on Artstation dynamic lighting hyperdetailed",
  "8k, rough, dark background, muted colors, epic composition, dramatic, digital painting, emotionally profound, very intricate",
  "Long Exposure Photography, high quality, highly detailed, dazzling display, translucent, beautiful silhouette light particles, backlit, back lights, HDR"
]

// Array of objects containing the template data
  const topicTemplates = [
    {
      id: 1,
      template: 'Who is/was/were/are X?',
      example: 'Who was Amelia Earhart?',
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/088lwignbp/out.mp4',
    },
    {
      id: 2,
      template: 'What is/was X?',
      example: 'What is the Doppler Effect?',
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/64zrxppeoj/out.mp4',
    },
    {
      id: 3,
      template: "X's greatest Y",
      example: "Glorfindel's greatest feats",
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/g8y8cl4asn/out.mp4',
    },
    {
      id: 4,
      template: 'How is/was/were X [action]?',
      example: 'How were the Pyramids made?',
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/ehpqhv3dex/out.mp4',
    },
    {
      id: 5,
      template: 'Can X [action] Y?',
      example: 'Can Itachi beat Jiraya?',
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/lkt97loc2i/out.mp4',
    },
    {
      id: 6,
      template: 'What if X?',
      example: 'What if everything is a dream?',
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/s4697uzmj7/out.mp4',
    },
    {
      id: 7,
      template: 'Why X is/was Y?',
      example: 'Why was slavery abolished?',
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/bvxpr80znx/out.mp4',
    },
    {
      id: 8,
      template: 'Lessons from X',
      example: 'Lessons from Plato',
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/1as78y75mu/out.mp4',
    },
    {
      id: 9,
      template: 'The history of X',
      example: 'The history of Peaky Blinders',
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/hdodxxcp68/out.mp4',
    },
    {
      id: 10,
      template: 'The legacy of X',
      example: 'The legacy of Cleopatra',
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/ll9autrrv1/out.mp4',
    },
    {
      id: 11,
      template: 'Does X like Y?',
      example: 'Does Deadpool like Spiderman?',
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/tenk3jeylm/out.mp4',
    },
    {
      id: 12,
      template: 'X the Y',
      example: 'Hippolyta the Queen of the Amazons',
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/v6u6x2pu6h/out.mp4',
    },
  ];

// Available music style tags
const STYLE_TAGS = {
  genres: [
    { label: 'Pop', value: 'pop' },
    { label: 'Rock', value: 'rock' },
    { label: 'Electronic', value: 'electronic' },
    { label: 'Hip Hop', value: 'hip_hop' },
    { label: 'Jazz', value: 'jazz' },
    { label: 'Classical', value: 'classical' },
    { label: 'R&B', value: 'r&b' },
    { label: 'Country', value: 'country' },
  ],
  moods: [
    { label: 'Upbeat', value: 'upbeat' },
    { label: 'Mellow', value: 'mellow' },
    { label: 'Energetic', value: 'energetic' },
    { label: 'Calm', value: 'calm' },
    { label: 'Dark', value: 'dark' },
    { label: 'Happy', value: 'happy' },
  ]
};


// Add this at the top of your MusicVideo component file
const VIDEO_FORMATS = {
  SQUARE: {
    label: "Square (1:1)",
    width: 1080,
    height: 1080,
    platforms: ["Instagram Post", "Facebook Post"],
    icon: "square"
  },
  LANDSCAPE: {
    label: "Landscape (16:9)",
    width: 1920,
    height: 1080,
    platforms: ["YouTube", "Desktop"],
    icon: "rectangle"
  },
  PORTRAIT: {
    label: "Portrait (9:16)",
    width: 1080,
    height: 1920,
    platforms: ["Instagram Stories", "TikTok", "YouTube Shorts"],
    icon: "vertical-rectangle"
  }
};

const MusicVideo = ({userData}) => {
  const [isMaintenanceMode] = useState(false);
  // console.log(userData.remaining);
  const [videoFormat, setVideoFormat] = useState('PORTRAIT'); // Default to portrait for social media

  const { currentUser } = useAuth();
  // const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);

  
  const [videoTitle, setVideoTitle] = useState('');
  const [selectedTags, setSelectedTags] = useState({
    genres: [],
    moods: []
  });

  const [generatedContent, setGeneratedContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [videoDuration, setVideoDuration] = useState(300);
  const [remaining, setRemaining] = useState(userData?.remaining);
  const [quota, setQuota] = useState(userData?.quota);
  const [rowId, setRowId] = useState(userData?.id)
  const [freeTrial, setFreeTrial] = useState(userData?.freeTrial)
  const [step, setStep] = useState(0)

  const [settingup, setSettingup] =  useState(false)
  const [videoUrl, setVideoUrl] =  useState("")


  const handleTagChange = (category, value) => {
    setSelectedTags(prev => ({
      ...prev,
      [category]: prev[category].includes(value)
        ? prev[category].filter(tag => tag !== value)
        : [...prev[category], value]
    }));
  };


 const uploadToS3 = async (file, folder, filename) => {
  const params = {
    Bucket: import.meta.env.VITE_S3_BUCKET_NAME,
    Key: `${folder}/${filename}`,
    Body: file,
    ContentType: getContentType(filename),
    ContentLength: file.size,
  };

  const data = await s3.upload(params).promise();
  return data.Location; // URL of the uploaded file
};


   const generateScript = async (title) => {
    const response = await openai.chat.completions.create({
        model: "gpt-4o",
        response_format: { "type": "json_object" },
        messages: [
            { 
                role: "system", 
                content: `
                    You are a helpful assistant that generates short video scripts. Provide the script text as JSON with the following schema:
                    {
                        "parts": [
                            {
                                "part": "string"
                            }
                        ]
                    }
                    Each part should be a single sentence of exactly 10 words. Do not include any meta text, scene descriptions, greetings, or instructions.
                `
            },
            { 
                role: "user", 
                content: `Generate a short script for a video titled "${title}". The script should be exactly 100 words long, divided into 10 parts. Each part should be a single sentence of exactly 10 words. Title is already present. Do not generate title. Start the script directly without title.` 
            }
        ],
    });
    // console.log(response.choices[0].message.content);
  
    const fullScript = JSON.parse(response.choices[0].message.content);
    // console.log(fullScript);
    
    return fullScript.parts.map(item => item.part);
};

  const generateVoiceover = async (script) => {
  const response = await openai.audio.speech.create({
    model: "tts-1",
    voice: "alloy",
    input: script,
  });

  const arrayBuffer = await response.arrayBuffer();
  const blob = new Blob([arrayBuffer], { type: 'audio/mpeg' });
  return blob;
};


const generatePrompts = async (title, scriptLines) => {
  try {
    const response = await openai.chat.completions.create({
      model: "gpt-4o",
      messages: [
        {
          role: "system",
          content: `
            You are a creative assistant specialized in generating prompts for image generation models. 
            For the title and each script line, generate a descriptive prompt that conveys the essence 
            and specifies details that would result in a visually appealing image. Your prompts should 
            be specific and provide clear imagery that aligns with the given text. The prompt should ensure no text appears on the image.
            Return the prompts as a single string, with each prompt separated by the delimiter "|". 
            Do not include any additional descriptors or labels; just provide the prompt text.
          `
        },
        {
          role: "user",
          content: `
            Generate image prompts based on the following title and script lines. 
            The title of the video is "${title}". The script lines are:
            ${scriptLines.join('\n')}
            There will always be 1 title and 10 script lines. So generate 11 prompts for 1 title and 10 script lines in that order. Provide the prompts in the specified format.
          `
        }
      ],
    });
    
    // console.log(response.choices[0].message.content);
    
    // Split the response based on the delimiter
    const promptsString = response.choices[0].message.content;
    const promptsArray = promptsString.split('|').map(prompt => prompt.trim());

    return promptsArray; // Return the array directly
  } catch (e) {
    console.error("Error generating prompts:", e.message);
    
    // Consider adding a delay before retrying to avoid rapid recursion
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    return generatePrompts(title, scriptLines); // Retry on error
  }
};


const generateImages = async (prompts) => {
    // console.log('Generating images with prompts:', prompts);

    // Array to hold all the promises
    const imagePromises = [];

    for (let index = 0; index < prompts.length; index++) {
      const prompt = prompts[index];

      // Push a new promise to the array
      imagePromises.push((async () => {
        // Make a POST request to generate an image
        const response = await fetch('https://spiff.guru/generate-image?prompt=' + prompt + '&format=' + videoFormat);

        // if (!response.ok) {
        //   throw new Error(`Failed to generate image for prompt ${index}`);
        // }

        // console.log(response);
        

        const data = await response?.json();
        const imageUrl = data?.image_url;

        // Fetch the generated image as a blob
        const imageResponse = await fetch(imageUrl);
        if (!imageResponse.ok) {
          throw new Error(`Failed to fetch image from URL ${imageUrl}`);
        }

        const imageBlob = await imageResponse.blob();
        return { index, blob: imageBlob };
      })());
    }

    // Wait for all image generation promises to complete
    const images = await Promise.all(imagePromises);

    // Sort images by index to ensure correct order
    images.sort((a, b) => a.index - b.index);

    // Convert blobs to files with proper naming
    const imageFiles = images.map((image, i) => ({
      filename: `image_${i + 1}.webp`,
      file: new File([image.blob], `image_${i + 1}.webp`, { type: 'image/webp' }),
    }));

    return imageFiles.map(image => image.file);
};


  const updateUsage = async () => {
    // Update the row in the Google Sheets API
    await fetch(`https://api.sheety.co/1f9a2b95f3899a00e7849c10b6ab36be/reelmaker/users/${rowId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user: {          
          remaining: remaining-1,          
        }
      })
    });

    setRemaining(remaining-1)
  }

const generateMusic = async (title) => {
  const allTags = [...selectedTags.genres, ...selectedTags.moods].join(' ');
  
  try {
    const response = await fetch('https://suno-api-new-xi.vercel.app/api/generate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        prompt: `${title}. Style: ${allTags || 'pop music'}`,
        make_instrumental: false,
        wait_audio: false  // Set to false for async generation
      }),
    });
    
    const data = await response.json();
    // console.log('generateMusic response:', data);
    
    if (!data || !Array.isArray(data) || !data[0]) {
      throw new Error('No valid response from music generation API');
    }

    // Return just the IDs from first two entries
    return [data[0].id, data[1].id];

  } catch (error) {
    console.error('Error generating music:', error);
    throw new Error('Failed in music generation: ' + error.message);
  }
};

const checkMusicStatus = async (clipIds, maxAttempts = 100) => {
  let attempts = 0;

  while (attempts < maxAttempts) {
    try {
      // Get all clips
      const response = await fetch('https://suno-api-new-xi.vercel.app/api/get')
      const allClips = await response.json();
      
      // Filter only the clips we're interested in
      const ourClips = allClips.filter(clip => clipIds.includes(clip.id));

      if (ourClips.length !== clipIds.length) {
        throw new Error('Some clips not found');
      }

      // Format the clips we need
      const musicClips = ourClips.map(clip => ({
        status: clip.status,
        audio_url: clip.audio_url,
        metadata: {
          prompt: clip.lyric,
          duration: clip.duration || 30,
        },
        title: clip.title,
        lyric: clip.lyric,
        created_at: clip.created_at
      }));

      // Check if all our clips are complete and have audio URLs
      const allComplete = musicClips.every(clip => 
        clip.status === 'complete' && clip.audio_url
      );
      
      if (allComplete) {
        return musicClips;
      }

      // Increment attempts and wait before next check
      attempts++;
      await new Promise(resolve => setTimeout(resolve, 5000));
      
    } catch (error) {
      console.error('Error checking music status:', error);
      attempts++;
      if (attempts >= maxAttempts) {
        throw new Error('Maximum attempts reached checking music status');
      }
      await new Promise(resolve => setTimeout(resolve, 5000));
    }
  }

  throw new Error('Timeout waiting for music generation');
};

// Helper function to calculate audio duration
const calculateAudioDuration = async (audioUrl) => {
  return new Promise((resolve) => {
    const audio = new Audio(audioUrl);
    audio.addEventListener('loadedmetadata', () => {
      resolve(Math.ceil(audio.duration));
    });
    audio.addEventListener('error', () => {
      console.warn('Failed to load audio duration, using default');
      resolve(30);
    });
    // Add timeout for audio loading
    setTimeout(() => {
      resolve(30);
    }, 5000);
  });
};

 // Update saveMusicVideo function to handle the new data structure
const saveMusicVideo = async (params) => {
  // console.log('saveMusicVideo params:', params);
  
  const url = 'https://api.sheety.co/1f9a2b95f3899a00e7849c10b6ab36be/reelmaker/musicVideos';
  
  const body = {
    musicvideo: {
      uid: currentUser.uid,
      title: params.title,
      tags: params.tags,
      musicUrl1: params.musicClips[0].audio_url,
      musicUrl2: params.musicClips[1].audio_url,
      images: params.imageUrls.join(','),
      videoUrl1: params.videoUrl1,
      videoUrl2: params.videoUrl2,
      videoDuration1: params.videoDuration1,
      videoDuration2: params.videoDuration2,
      lyrics: params.musicClips[0].lyric,      
    }
  };

  const data = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return await data.json();
};

  const getMusicDuration = (clip) => {
  // If clip has duration in metadata, use it
  if (clip?.metadata?.duration) {
    return clip.metadata.duration;
  }
  
  // Default duration if no metadata found
  console.warn('No duration found in clip metadata, using default');
  return 30; // Default 30 seconds
};

const renderVideo = async (params) => {
  // console.log('renderVideo', params);

  const reqparams = {
    uid: params.uid,
    title: params.title,
    music: params.music,
    images: params.images,
    videoDuration: params.videoDuration,
    format: params.format,
    type: 'music',  // Specify this is a music video
    // Remove unnecessary parameters since they're handled in the composition
  }
  
  // console.log(reqparams);
    
  
  try {
    // Call the render API
    const renderResponse = await fetch('https://spiff.guru/render', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reqparams),
    });

    if (!renderResponse.ok) {
      throw new Error(`Render API error: ${renderResponse.statusText}`);
    }

    const renderData = await renderResponse.json();

    if (!renderData?.outputFile) {
      throw new Error('No output file in render response');
    }

    return {
      outputFile: renderData.outputFile,
      status: 'success',
      duration: params.videoDuration,
      message: 'Music video rendered successfully'
    };

  } catch (error) {
    console.error('Error rendering video:', error);
    throw new Error(`Failed to render video: ${error.message}`);
  }
};



// Assuming generatePrompts returns an array of strings
const modifyPrompts = async (videoTitle, lyric) => {
    // Get initial prompts
    const initialPrompts = await generatePrompts(videoTitle, [lyric]);
    
    // Create a new array of exactly 11 elements
    const finalPrompts = new Array(11).fill('').map((_, index) => {
        if (index < initialPrompts.length && initialPrompts[index]) {
            // Use the original prompt if it exists and is not empty
            return initialPrompts[index];
        } else if (index === 0) {
            // If first element is empty, use a default value or videoTitle
            return videoTitle;
        } else {
            // Use the previous non-empty value
            return finalPrompts[index - 1];
        }
    });

    console.log('finalPrompts', finalPrompts);
    

    return finalPrompts;
};

// Update handleSubmit to use the new synchronous flow
const handleSubmit = async (e) => {
  e.preventDefault();

  if (remaining <= 0) {
    alert('Quota Exhausted. Please upgrade for more or wait for quota reset.');
    return;
  }

  if(videoTitle.length > 50) {
    alert('Video title too long. Max 50 characters.');
    return;
  }

  setIsLoading(true);
  setError(null);

  try {
    // Generate initial music clips
    const clipIds = await generateMusic(videoTitle);
    setStep(1);
    // console.log('Generated clip IDs:', clipIds);
    
    // Wait for music generation to complete with status checks
    const musicClips = await checkMusicStatus(clipIds);
    setStep(2);
    // console.log('Music clips ready:', musicClips);

    // Generate images using lyrics from first music clip
    const folderName = generateFolderName(videoTitle, currentUser.uid);
    const prompts = await modifyPrompts(videoTitle, musicClips[0].lyric);
    const images = await generateImages(prompts);
    console.log('images', images);
    
    const imageUrls = await Promise.all(images.map((image, index) => 
      uploadToS3(image, folderName, `image${index + 1}.webp`)
    ));

    console.log('imageUrls', imageUrls);
    
    setStep(3);

    // Get selected format dimensions
    const format = VIDEO_FORMATS[videoFormat];
    
    // Render both versions with selected format
    const video1 = await renderVideo({
        uid: currentUser.uid,
        title: `${videoTitle}`,
        music: musicClips[0].audio_url,
        images: imageUrls,
        videoDuration: Math.ceil(musicClips[0].metadata.duration * 30),
        format: videoFormat,
      })
    
      console.log(video1);
      
    
    const video2 = await renderVideo({
        uid: currentUser.uid,
        title: `${videoTitle}`,
        music: musicClips[1].audio_url,
        images: imageUrls,
        videoDuration: Math.ceil(musicClips[1].metadata.duration * 30),
        format: videoFormat,
      })

    console.log(video2);


    setStep(4);

    // Save to sheet with updated music clip data
    const savedData = await saveMusicVideo({
      title: videoTitle,
      tags: [...selectedTags.genres, ...selectedTags.moods].join(' '),
      musicClips,
      imageUrls,
      videoUrl1: video1.outputFile,
      videoUrl2: video2.outputFile,
      videoDuration1: Math.ceil(musicClips[0].metadata.duration * 30),
      videoDuration2: Math.ceil(musicClips[1].metadata.duration * 30)
    });
    setStep(5);

    await updateUsage();
    setStep(6);

    // Update UI with all generated content
    setGeneratedContent({
      id: savedData?.musicVideo?.id,
      title: videoTitle,
      version1: {
        music: musicClips[0].audio_url,
        video: video1.outputFile,
        duration: Math.ceil(musicClips[0].metadata.duration * 30),
        lyrics: musicClips[0].lyric
      },
      version2: {
        music: musicClips[1].audio_url,
        video: video2.outputFile,
        duration: Math.ceil(musicClips[1].metadata.duration * 30),
        lyrics: musicClips[1].lyric
      },
      images: imageUrls,
      format: videoFormat
    });    

  } catch (err) {
    console.error('Error in submission:', err);
    let errorMessage = 'An error occurred while generating the music video content.';
    
    // Specific error messages based on the stage where error occurred
    if (err.message.includes('music generation')) {
      errorMessage = 'Failed to generate music. Please try again.';
    } else if (err.message.includes('Maximum attempts')) {
      errorMessage = 'Music generation is taking longer than expected. Please try again.';
    } else if (err.message.includes('status check')) {
      errorMessage = 'Failed to verify music generation status. Please try again.';
    } else if (err.message.includes('image')) {
      errorMessage = 'Failed to generate images. Please try again.';
    } else if (err.message.includes('render')) {
      errorMessage = 'Failed to render video. Please try again.';
    } else if (err.message.includes('upload')) {
      errorMessage = 'Failed to save generated content. Please try again.';
    }

    setError(errorMessage);
    setGeneratedContent(null);
    setStep(0);
    setVideoDuration(null);
    setVideoUrl('');

    // Log detailed error for debugging
    console.error('Detailed error:', {
      message: err.message,
      step: step,
      title: videoTitle,
      format: videoFormat
    });

  } finally {
    setIsLoading(false);
  }
};
  // useEffect(() => {
  //   const checkSubscriptionStatus = async () => {
  //     if (!currentUser) {
  //       navigate('/login');
  //       return;
  //     }

  //     setSettingup(true)

  //     const email = currentUser.email;
  //     try {
  //       const sheetyResponse = await fetch(`https://api.sheety.co/1f9a2b95f3899a00e7849c10b6ab36be/reelmaker/users?filter[email]=${email}`);
  //       const sheetyData = await sheetyResponse.json();
  //       const userData = sheetyData.users[0];

  //       if (!userData || userData.subscriptionStatus !== 'active') {
  //         navigate('/subscribe');
  //       }

  //       setQuota(userData.quota)
  //       setRemaining(userData.remaining)
  //       setRowId(userData.id)
  //     } catch (error) {
  //       console.error('Error fetching subscription status:', error);
  //       window.location.reload
  //       // navigate('/pricing');
  //     } finally {
  //       setSettingup(false)
  //     }
  //   };

  //   checkSubscriptionStatus();
  // }, [currentUser, navigate]);

  if (settingup) {
    return (
      <div className={styles["dashboard-container"]}>
        <div className={styles["form-container"]} style={{textAlign: 'center'}}>
          <Loader color="indigo" size="xl" />
        </div>
        <Container>
          <Skeleton height={50} circle mb="xl" />
          <Skeleton height={8} radius="xl" />
          <Skeleton height={8} mt={6} radius="xl" />
          <Skeleton height={8} mt={6} width="70%" radius="xl" />
        </Container>
      </div>
    )
  }

  return (
   <div className={styles.section}>
    {isMaintenanceMode && <div className={styles.section}>
      <MaintenanceOverlay />
    </div>}
      <Sidebar />
      <div className={styles["dashboard-container"]}>
        <div className={styles["dashboard-header"]}>
          <h2><IconVideoPlus size="1.4rem" stroke={2.5} /> New Music Video</h2>
          <div>
            <Badge
              size="md"
              variant="gradient"
              gradient={{ from: 'pink', to: 'teal', deg: 208 }}
            >
              {freeTrial ? "Free Trial" : "Usage Remaining"}: {remaining}/{quota}
            </Badge>
          </div>
        </div>
        
        {!generatedContent && (
          <div className={styles["form-container"]}>
            <Card>
              <form onSubmit={handleSubmit}>
                <Stack spacing="md">
                  <Input 
                    className={styles.input}
                    value={videoTitle}
                    onChange={(e) => setVideoTitle(e.target.value)}
                    placeholder="Enter Music Video Title"
                    required 
                    maxLength={50} 
                  />
                  
                  <div>
                    <Text weight={500} size="sm" mb={10}>Select Genres:</Text>
                    <Group>
                      {STYLE_TAGS.genres.map((tag) => (
                        <Checkbox
                          key={tag.value}
                          label={tag.label}
                          checked={selectedTags.genres.includes(tag.value)}
                          onChange={() => handleTagChange('genres', tag.value)}
                        />
                      ))}
                    </Group>
                  </div>

                  <div>
                    <Text weight={500} size="sm" mb={10}>Select Moods:</Text>
                    <Group>
                      {STYLE_TAGS.moods.map((tag) => (
                        <Checkbox
                          key={tag.value}
                          label={tag.label}
                          checked={selectedTags.moods.includes(tag.value)}
                          onChange={() => handleTagChange('moods', tag.value)}
                        />
                      ))}
                    </Group>
                  </div>

                  {/* Format selection */}
                  <FormatSelect 
                    value={videoFormat} 
                    onChange={setVideoFormat} 
                  />
                  
                  <Button 
                    className={styles.button} 
                    type="submit" 
                    loading={isLoading} 
                    loaderProps={{ type: 'dots' }} 
                    leftSection={<IconSparkles />} 
                    size="md"
                  >
                    Generate Music Video
                  </Button>
                </Stack>
              </form>
            </Card>
          </div>
        )}

 {isLoading && (
  <>
    <Progress.Root size="xl">
      {step >= 1 && (
        <Progress.Section value={20} color="blue">
          <Progress.Label>Generating Music (Version 1)</Progress.Label>
        </Progress.Section>
      )}
      {step >= 2 && (
        <Progress.Section value={20} color="violet">
          <Progress.Label>Generating Music (Version 2)</Progress.Label>
        </Progress.Section>
      )}
      {step >= 3 && (
        <Progress.Section value={20} color="orange">
          <Progress.Label>Creating Visuals</Progress.Label>
        </Progress.Section>
      )}
      {step >= 4 && (
        <Progress.Section value={20} color="pink">
          <Progress.Label>Rendering Music Videos</Progress.Label>
        </Progress.Section>
      )}
      {step >= 5 && (
        <Progress.Section value={20} color="green">
          <Progress.Label>Finalizing</Progress.Label>
        </Progress.Section>
      )}
      <Progress.Section value={PROGRESS[step]} color="gray">
        <Progress.Label>Processing...</Progress.Label>
      </Progress.Section>
    </Progress.Root>

    <Card shadow="sm" padding="lg" radius="md" withBorder className={styles.card} mt="md">
      <Center>
        <Loader color="indigo" size="xl" />
      </Center>
      <h2 style={{ textAlign: 'center', marginTop: '1rem' }}>
        {step === 0 && "Initializing..."}
        {step === 1 && "Generating First Music Version..."}
        {step === 2 && "Generating Second Music Version..."}
        {step === 3 && "Creating Visual Elements..."}
        {step === 4 && "Rendering Both Music Videos..."}
        {step === 5 && "Saving Your Music Videos..."}
      </h2>
      <p style={{ textAlign: 'center', color: 'gray' }}>This process may take a few minutes. Please don't close this window.</p>
      <p>ETA: 4 minutes</p>
    </Card>
  </>
)}
        
       {generatedContent && (
          <div className={styles["generated-content"]}>
            <Card shadow="sm" padding="lg" radius="md" withBorder className={styles.card}>
              <Center>
                <IconCircleCheck size="5rem" stroke={2.5} color="green" />
              </Center>
              <h2>Music Videos Generated Successfully</h2>
              <Center>            
              <Group>    
                  <Button 
                    className={styles.button}
                    component={Link} 
                    to={`/music-video/${generatedContent.id}`} 
                    leftSection={<IconEye />}
                  >
                    View Music Video
                  </Button>
              </Group>
                </Center>
            </Card>
          </div>
        )}

        <UpgradeCard remaining={remaining} accountEmail={currentUser?.email} quota={quota} />
      </div>
    </div>
  );
};

const FormatSelect = ({ value, onChange }) => {
  return (
    <Stack spacing="xs">
      <Text weight={500} size="sm">Select Video Format:</Text>
      <Radio.Group
        value={value}
        onChange={onChange}
        name="videoFormat"
        classNames={{ label: styles.radioLabel }}
      >
        <Group mt="xs">
          {Object.entries(VIDEO_FORMATS).map(([key, format]) => (
            <Tooltip
              key={key}
              label={`Perfect for: ${format.platforms.join(', ')}`}
              position="bottom"
              withArrow
            >
              <Radio
                value={key}
                label={
                  <Group spacing="xs">
                    <ThemeIcon size="md" variant="light">
                      {key === 'SQUARE' && <IconSquare size="1.2rem" />}
                      {key === 'LANDSCAPE' && <IconRectangle size="1.2rem" />}
                      {key === 'PORTRAIT' && <IconRectangleVertical size="1.2rem" />}
                    </ThemeIcon>
                    <div>
                      <Text size="sm">{format.label}</Text>
                      <Text size="xs" color="dimmed">{format.width}×{format.height}</Text>
                    </div>
                  </Group>
                }
              />
            </Tooltip>
          ))}
        </Group>
      </Radio.Group>
    </Stack>
  );
};


export default MusicVideo;
