import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Image, Title, Text, Loader, Anchor, Button, Skeleton, Group, Card, Accordion } from '@mantine/core';
import styles from './ReelDetail.module.css';
import Sidebar from './Sidebar';
import { IconDownload, IconHeadphones, IconMovie, IconPhoto, IconPlus, IconScript } from '@tabler/icons-react';

export const transformVideoUrl = (s3Url) => {
  try {
    // Use regex to extract the code between renders/ and /out.mp4
    const match = s3Url.match(/renders\/([^/]+)\/out\.mp4/);
    
    if (!match) {
      console.error('Invalid URL format');
      return null;
    }
    
    const renderCode = match[1];
    return `https://d27ug7nm5nfkza.cloudfront.net/renders/${renderCode}/out.mp4`;
  } catch (error) {
    console.error('Error transforming URL:', error);
    return null;
  }
};


const ReelDetail = () => {
  const { id } = useParams();
  const [reel, setReel] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const url = `https://api.sheety.co/1f9a2b95f3899a00e7849c10b6ab36be/reelmaker/reels/${id}`;
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        setReel(json.reel);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching reel:', error);
        setLoading(false);
      });
  }, [id]);


  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = transformVideoUrl(reel.videoUrl);
    link.setAttribute('download', `${reel.title}.mp4`);
    link.setAttribute('target', '_blank')
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return (
      <div style={{textAlign: 'center', marginTop: '10vh'}}>
        <Loader color="indigo" size="xl" />;
        <Container>
          <Skeleton height={50} circle mb="xl" />
          <Skeleton height={8} radius="xl" />
          <Skeleton height={8} mt={6} radius="xl" />
          <Skeleton height={8} mt={6} width="70%" radius="xl" />
        </Container>
      </div>
    )
  }

  if (!reel) {
    return <Text className={styles.error}>Reel not found</Text>;
  }

  return (
    <div className={styles.section}>
      <Sidebar />
      <div className={styles.reelDetail}>
        <Container className={styles.container}>
          <Group className={styles.header}>
            <h2 style={{color: "#474f85"}}><IconMovie size="1.2rem" stroke={2.5} /> {reel.title}</h2>
            <Button className={styles.button} component={Link} to="/generate-new-video" leftSection={<IconPlus size="1rem" stroke={2.5} />}>New Video</Button>
          </Group>
          {/* <Image src={reel.thumbnail} height={400} alt={reel.title} className={styles.image} /> */}
          {/* <Title className={styles.title}>{reel.title}</Title> */}
          
          {/* <Card shadow="sm" padding="xl"> */}
            <div className={styles.layout}>

              <div className={styles.left}>
                <div className={styles.videoContainer}>               
                  <video controls className={styles.video}>
                    <source src={transformVideoUrl(reel.videoUrl)} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <Button onClick={handleDownload} className={styles.button} leftSection={<IconDownload />} style={{width: 300}}>
                  Download Video
                </Button>
              </div>

              <div className={styles.right}>
                <Accordion defaultValue="Script" variant="separated">
                  <Accordion.Item value={'Script'}>
                    <Accordion.Control icon={<IconScript />}>{'Script'}</Accordion.Control>
                    <Accordion.Panel>{reel.script}</Accordion.Panel>
                  </Accordion.Item>
                  <Accordion.Item value={'Title Voiceover'}>
                    <Accordion.Control icon={<IconHeadphones />}>{'Title Voiceover'}</Accordion.Control>
                    <Accordion.Panel>
                      <audio controls src={reel.titleVoiceover}>
                        Your browser does not support the audio element.
                      </audio>
                    </Accordion.Panel>
                  </Accordion.Item>
                  <Accordion.Item value={'Voiceover'}>
                    <Accordion.Control icon={<IconHeadphones />}>{'Voiceover'}</Accordion.Control>
                    <Accordion.Panel>
                      <audio controls src={reel.voiceover}>
                        Your browser does not support the audio element.
                      </audio>
                    </Accordion.Panel>
                  </Accordion.Item>

                  <Accordion.Item value={'Images'}>
                    <Accordion.Control icon={<IconPhoto />}>{'Images'}</Accordion.Control>
                    <Accordion.Panel>
                      <div style={{display: 'flex', overflow: 'scroll'}}>
                        {reel?.images?.split(",").map((image, index) => (
                          <img className={styles.image} style={{height: '370px', width: '40%'}} key={index} src={image} alt={`Generated image ${index + 1}`} />
                        ))}
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>                
              </div>                
              {/* </div> */}
            </div>
          {/* </Card> */}


          {/* <Text className={styles.description}>{reel.description}</Text> */}
          {/* <Text className={styles.content}>{reel.content}</Text> */}
        </Container>
      </div>
    </div>
  );
};

export default ReelDetail;
